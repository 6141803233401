import ReactDOM, { Container } from 'react-dom/client';

import { ComponentsShell } from '@/components/shell';

export const replaceHydrateFunction = () => {
  return (element: JSX.Element, container: Container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement = ({ element }: any) => (
  <ComponentsShell>{element}</ComponentsShell>
);
