import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import '@/styles/components/swiper.css';
import '@/styles/base.scss';

import type { FC, PropsWithChildren } from 'react';

import { ViewportProvider } from '@/context/viewport';

export const ComponentsShell: FC<PropsWithChildren> = ({
  children,
}) => {
  return <ViewportProvider>{children}</ViewportProvider>;
};
